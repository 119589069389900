import React, { useEffect, useState } from 'react'
import './footer.css'
import footerlogo from '../../../assets/images/Royal77701.png'
import Container from '../Container'
import { Link, useLocation } from 'react-router-dom'

function Footer() {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const [domainName, setDomainName] = useState('');
  const [dynamicWidth, setDynamicWidth] = useState('160px');

  useEffect(() => {
      const screenWidth = window.innerWidth;
      let data = localStorage.getItem('logoData')
      let datas = JSON.parse(data)
      if(datas !== null){
        if (screenWidth <= 767) {
          setDynamicWidth(`${datas.SM}px`);
        } else if (screenWidth <= 999) {
          setDynamicWidth(`${datas.MD}px`);
        } else if (screenWidth <= 1150) {
          setDynamicWidth(`${datas.LG}px`);
        } else {
          setDynamicWidth(`${datas.XL}px`);
        }
      }
    }, [dynamicWidth ]);

  useEffect(() => {
    const hostname = window.location.hostname;
    setDomainName(hostname);
    if(location.pathname==='/'){
      setShow(true)
    }
    return () => {
      setShow(false)
    }
  }, [location.pathname])


  return (
    <>
    {show && (
    <footer className='footer'>
        <Container size='full'>
        <div className='footer-row'>
        <div className='footer-logo'> <Link to='/'><img style={{ maxWidth: dynamicWidth }} src={`https://admin.${domainName}/logo/${domainName}2.webp`} alt={''}/> </Link> </div>
        {/* <div className='footer-links'>
          <div className='link'><Link to='/'>About us</Link></div>
          <div className='link'><Link to='/'>Gambling Responsibilities</Link></div>
          <div className='link'><Link to='/'>Terms & Conditions</Link></div>
          <div className='link'><Link to='/'>Rules and Regulations</Link></div>
        </div> */}
        </div>
        </Container>
    </footer>
    )}
    </>
  )
}

export default Footer