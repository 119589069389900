import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Container from '../../components/common/Container';
import useSocket from '../../Websoket.js';
import MatchOdds from '../../components/matchData/MatchOdds.js'
import MatchOdds2 from '../../components/matchData/MatchOdds2.js'
import Toss from '../../components/matchData/Toss.js'
import BookMaker from '../../components/matchData/BookMaker.js'
import OtherBookmaker from '../../components/matchData/OtherBookmaker.js';
import OddsFeedComponent from "../../components/matchData/OddsFeedComponent.js";
import Fancy from '../../components/matchData/Fancy/Fancy.js'
import Popup from '../../components/common/popup/Popup.js'
import Scoreboard from '../../components/Scoreboard/Scoreboard.js';
import { PopupContext } from '../../components/LoginPopup/LoginPopupContext.js';
import MatchNotFound from '../../components/matchData/MatchNotFound.js';
import OddsFeedComponenttt from '../../components/allsportdata/OddsFeedComponenttt.js';
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";

function ExchangeInnerHG() {
    const { eventId,marketId } = useParams();
    let userInfo = sessionStorage.getItem('userinfo')
    let matchDataAccess = {
        "eventId": eventId,
        "userId": '65fc48e7eab864a79776c01c'
    };
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        matchDataAccess = {
            "eventId": eventId,
            "userId": userInfo._id
        };

    }
    let isHRGHMarket = false;
    if(marketId){
        isHRGHMarket = true
    }

    const [matchData, setMatchData] = useState(matchDataAccess);
    const [getMatchOdds, setGetMatchOdds] = useState(null);
    const [getMatchOdds2, setGetMatchOdds2] = useState(null);
    const [getBookmaker, setGetBookmaker] = useState(null);
    const [getOtherBookmaker, setGetOtherBookmaker] = useState(null);
    const [getUnderOver, setGetUnderOver] = useState(null);
    const [getToss, setToss] = useState(null);
    const [getFancy, setGetFancy] = useState(null);
    const [betData, setBetData] = useState([]);
    const [betPopup, setBetPopup] = useState(false);
    const [finalBetData, setFinalBetData] = useState({});
    const [betresultMsg, setBetresultMsg] = useState();
    const [betlistData, setBetlistData] = useState(matchDataAccess);
    const { isConnected, receivedMessages, receivedBetlistData, sendMessage,clearCashout,receivedToken } = useSocket();
    const [liveStreamSlide, setLiveStreamSlide] = useState('open');
    const { loggedIn, betUpdated, betNotUpdated, isBetUpdate,setBetLoder,unsetBetLoader,isBetLoader, loaderCounting,clearSecId,sessionUpdate, sessionUpdatevalue } = useContext(PopupContext);
    const [isLoggedIn, setIsLoggedIn] = useState(!!sessionStorage.getItem('loggedIn'));
    const [betLoginShow, setBetLoginShow] = useState();
    const [matchStatuss, setMatchStatus] = useState(0);
    const [myNotification, setMyNotification] = useState();
    // console.log(getOtherBookmaker, 'receivedMessagesreceivedMessagesreceivedMessagesreceivedMessages');

     useEffect(()=>{
            getSubNotification(eventId)
        },[eventId])

    const getSubNotification = (ids) => {
        let data = JSON.stringify({
            "eventId":ids
        })
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: data,
        };
    
        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/notification/getmarketNotitfication`, requestOptions)
          .then(async (response) => {
            let paresData = await response.json();
            setMyNotification(paresData?.notifications);
            console.log("getmarketNotitfication - ", paresData?.notifications);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    useEffect(() => {
        let timer;
        if (isBetLoader) {
          timer = setInterval(() => {
            loaderCounting(prevSeconds => prevSeconds + 1);
          }, 1000);
        } else {
          loaderCounting(0);
          clearInterval(timer);
        }
        return () => clearInterval(timer);
      }, [isBetLoader]);


      

    const checkGameStart = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if(isLoggedIn || loggedIn){
          myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
        }

       const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/matchStatus?eventId=${eventId}`, requestOptions)
          .then(async (response) => {
            let paresData = await response.json();
            if(response.ok){
                setMatchStatus(paresData?.matchStatus === 'IN_PLAY' || paresData?.matchStatus === 'UPCOMING' ? 1 : 2)
            }else{
                setMatchStatus(2)
            }
            // console.log("matchStatus - ", paresData);
          })
          .catch((error) => {
            console.log(error);
          });
    };

    useEffect(() => {
        checkGameStart();
        if (betlistData && isConnected) {
            sendMessage('getbetlistdata', betlistData);
        }

        if (isLoggedIn || loggedIn) {
            setBetLoginShow(true)
        } else {
            setBetLoginShow(false)
        }
    }, [loggedIn, isLoggedIn, betLoginShow, isBetUpdate,isBetLoader,unsetBetLoader,setBetLoder])

    useEffect(() => {
        if (isConnected && matchData && betlistData) {

            sendMessage('inplaymatchdetails', matchData);
            let intervalId;
            if (userInfo) {
                sendMessage('getbetlistdata', betlistData);

                intervalId = setInterval(() => {
                    sendMessage('getbetlistdata', betlistData);
                }, 5000);
            }
            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }
    }, [isConnected, matchData, betlistData, sendMessage]);

    const liveStreamHandle = () => {
        setLiveStreamSlide(!liveStreamSlide)
    }
    const sbData = receivedMessages?.data?.matchData;

    const getMatchOddsHandle = (data) => {
        setGetMatchOdds(data);
    };
    const getMatchOddsHandle2 = (data) => {
        setGetMatchOdds2(data);
    };
    const getToasHandle = (data) => {
        setToss(data);
    };
    const getUnderOverHandle = (data) => {
        setGetUnderOver(data);
    };
    const getBookmakerHandle = (data) => {
        setGetBookmaker(data);
    };
    const getOtherBookmakerHandle = (data) => {
        setGetOtherBookmaker(data);
    };
    const getFancyHandle = (data) => {
        setGetFancy(data);
    };
    const token = sessionStorage.getItem('token');

    const getfancyHandle = (data) => {
        betUpdated();
        setBetLoder();
        // console.log(data, 'got hretftftftftf');
        let fancyData = {
            eventId: eventId,
            marketId: data?.marketId,
            stake: data?.stake,
            betType: data?.bklyClassValue,
            selectionName: data?.selectionName,
            odds: data?.odds,
            rate: data?.rate
        }
        if (fancyData.betType === 'back') {
            fancyData.betType = 'BACK'
        } else {
            fancyData.betType = 'LAY'
        }


        callBetPlace(fancyData);

    }

    const updateSessionStorage = () => {
        const newValue = `Updated at ${new Date().toLocaleTimeString()}`;
        sessionStorage.setItem("sharedData", newValue);
        sessionUpdate(newValue);
      };

      const publicKeyPEM = `-----BEGIN PUBLIC KEY-----
          MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKik5c/NUnfRBlOR6fgoSwG5YMEgclwe
          BBcPrHR452svxh9lPPNUr8stKqglGU40+ilIzXlsKlZ1sMKh4w6+O6MCAwEAAQ==
          -----END PUBLIC KEY-----`;
      
      // Generate AES key
      const generateAESKey = () => {
          return CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex); // 16-byte key
      };
      
      // Encrypt with AES
      const encryptWithAES = (text, key) => {
          return CryptoJS.AES.encrypt(text, key).toString();
      };
      
      // Encrypt AES Key with RSA
      const encryptAESKeyWithRSA = (aesKey) => {
          return new Promise((resolve, reject) => {
              const encryptor = new JSEncrypt();
              encryptor.setPublicKey(publicKeyPEM);
              const encryptedKey = encryptor.encrypt(aesKey);
              
              if (encryptedKey) {
                  resolve(encryptedKey);
              } else {
                  reject("RSA Encryption failed!");
              }
          });
      };
          
          // Main function
          const encryptLargeMessage = async (message) => {
              try {
                  const aesKey = generateAESKey(); // Step 1: Generate AES Key
                  const encryptedMessage = encryptWithAES(message, aesKey); // Step 2: Encrypt Message
                  
                  // Step 3: Encrypt AES Key with RSA (using await)
                  const encryptedAESKey = await encryptAESKeyWithRSA(aesKey);
          
                  return {
                      encryptedMessage,
                      encryptedAESKey
                  };
              } catch (error) {
                  console.error("Encryption failed:", error);
              }
          };

      useEffect(() => {
      
              const encryptData = async () => {
                  let final = {};
                  if (receivedToken.token) {
                      console.log(receivedToken.token, "receivedTokenreceivedTokenreceivedToken");
                      let user = JSON.parse(sessionStorage.getItem('userinfo'));
                      let string = `${user._id}_${receivedToken.token}_${user.userName}`;
                      // Encrypt data
                      const enc = await encryptLargeMessage(string);
                      let msg = `${enc.encryptedAESKey}_token_${enc.encryptedMessage}`
                      final = {
                          ...finalBetData,    // If there's any existing data, retain it
                          msg: msg , // Add the new token
                      };
                      
                      try {
                          // Replace 'your-token' with your actual token
                          const myHeaders = new Headers();
                          myHeaders.append("Content-Type", "application/json");
                          myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
                          console.log("request :::::: ", final)
                          const requestOptions = {
                              method: "POST",
                              headers: myHeaders,
                              redirect: "follow",
                              body: JSON.stringify(final)
                            };
              
                            fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/bets/placeBet`, requestOptions)
                            .then(async (response) => {        
                              let result = await response.json();
                              console.log("Response :::: ",result);
                              setBetresultMsg(result);
                              setBetPopup(true);
                              unsetBetLoader();
                              clearCashout();
                              setFinalBetData({});
                              updateSessionStorage();
                                  const betResultpopupTimeer = setTimeout(() => {
                                      clearSecId();
                                      setBetPopup(false);
                                  }, 1500);
                                  return () => {
                                      clearSecId();
                                      clearTimeout(betResultpopupTimeer);
                                  }
                            })
                            .catch((error) => {
                              clearSecId();
                              clearCashout();
                              console.log(error);
                            });
              
                      } catch (error) {
                          clearCashout();
                          console.error('Error fetching API data:', error);
                      }
                  }
              };
              encryptData();
          }, [receivedToken])

          const callBetPlace = (data) => {
            setFinalBetData(data);
            let user = JSON.parse(sessionStorage.getItem('userinfo'));
            sendMessage("createToken",{userName:user.userName});
        }

    // const callBetPlace = (data) => {
    //     try {
    //         // Replace 'your-token' with your actual token
    //         const myHeaders = new Headers();
    //         myHeaders.append("Content-Type", "application/json");
    //         myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
    //         console.log("request :::::: ", data)
    //         const requestOptions = {
    //             method: "POST",
    //             headers: myHeaders,
    //             redirect: "follow",
    //             body: JSON.stringify(data)
    //           };

    //           fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/bets/placeBet`, requestOptions)
    //           .then(async (response) => {
    //             updateSessionStorage();
    //             let result = await response.json();
    //             console.log("Response :::: ",result);
    //             setBetresultMsg(result);
    //             setBetPopup(true);
    //             unsetBetLoader();
    //             clearCashout();
    //                 const betResultpopupTimeer = setTimeout(() => {
    //                     clearSecId();
    //                     setBetPopup(false);
    //                 }, 1500);
    //                 return () => {
    //                     clearSecId();
    //                     clearTimeout(betResultpopupTimeer);
    //                 }
    //           })
    //           .catch((error) => {
    //             clearSecId();
    //             clearCashout();
    //             console.log(error);
    //           });

    //     } catch (error) {
    //         clearCashout();
    //         console.error('Error fetching API data:', error);
    //     }
    // }

    const getBetDataHandle = (getBetSlipData) => {
        // console.log(getBetSlipData,'-----------------------******-----------');

        betUpdated();
        setBetLoder();
        setBetData(getBetSlipData);
        let data = {
            eventId: eventId,
            marketId: getBetSlipData?.marketId,
            stake: getBetSlipData?.stake,
            betType: getBetSlipData?.bklyClassValue,
            selectionName: getBetSlipData?.selectionName,
            secId: `${getBetSlipData?.secId}`,
            odds: getBetSlipData?.oddsValue
        }
        console.log('datadatadata', data)
        if (data.betType === 'back') {
            data.betType = 'BACK'
        } else {
            data.betType = 'LAY'
        }

        callBetPlace(data);
    };
    return (
        <>
            <Container size='full'>
            {matchStatuss && matchStatuss === 1 ?
                <div className='content-w-sidebar exchange-inner-page'>
                    <div className='left-content'>
                        <div className='match-data-wraper'>
                        {(receivedMessages.data && isHRGHMarket) ?
                            <MatchOdds2 eventAccess={receivedMessages.data} getMatchOdds={getMatchOdds2} onBetClick={getBetDataHandle} getUnderOver={getUnderOver} isHRGHMarket={isHRGHMarket} HGMarketId = {marketId} myNotification={myNotification} /> : ''
                        }
                        {receivedMessages.data ?
                            <>
                            <OddsFeedComponenttt eventAccess={receivedMessages.data} getMatchOddsHandle={getMatchOddsHandle} getMatchOddsHandle2={getMatchOddsHandle2} HGMarketId = {marketId} getToasHandle={getToasHandle} getBookmakerHandle={getBookmakerHandle} getOtherBookmakerHandle={getOtherBookmakerHandle} getFancyHandle={getFancyHandle}  getUnderOverHandle={getUnderOverHandle}/>
                            </> : ''

                        }
                    </div>

                    </div>
                    {receivedMessages.data ?
                    <div className='right-content sidebar'>
                        <div className='sidebar-box'>
                            <div className='title'>Open Bets ({receivedBetlistData?.bets?.length})</div>
                            {receivedBetlistData?.bets?.length > 0 ?
                                <div className='betlist'>
                                    {betLoginShow ?
                                        <table className="betlist-table">
                                            <thead>
                                                <tr>
                                                    <th>Selection</th>
                                                    <th>Odds</th>
                                                    <th>Market</th>
                                                    <th>Stake</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {receivedBetlistData?.bets?.map((item, index) => (
                                                    <tr key={index} className={`${item?.bettype2 === 'BACK' ? 'back' : 'lay'}`}>
                                                        <td> {item?.selectionName}</td>
                                                        <td> {item?.oddValue}</td>
                                                        <td> {item?.marketName}</td>
                                                        <td> {item?.Stake}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> : ''}
                                </div> : ''}
                        </div>
                    </div> : <div className='sidebar skeleton'></div>
                    }
                </div>
                :
                <div className={`${matchStatuss === 0 ? 'hide' : matchStatuss === 1 ? 'hide' : 'show'}`}> <MatchNotFound />  </div>}

            </Container>
            <Popup popupClass={`alertMsgpopup ${betPopup ? 'open' : ''}`} content={<div className={`alert-msg ${betresultMsg?.status === 'Error' ? 'err' : ''}`}> <span className='msg'>{betresultMsg?.status === 'success' ? betresultMsg?.msg : betresultMsg?.message}</span><div className="alert-progress-bar"></div> </div>} />

        </>
    )
}

export default ExchangeInnerHG