import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Container from '../../components/common/Container.js';
import useSocket from '../../Websoket.js';
import MatchOdds from '../../components/matchData/MatchOdds.js'
import Toss from '../../components/matchData/Toss.js'
import BookMaker from '../../components/matchData/BookMaker.js'
import Fancy from '../../components/matchData/Fancy/Fancy.js'
import Fancy2 from '../../components/matchData/Fancy/Fancy2.js'
import Popup from '../../components/common/popup/Popup.js'
import Scoreboard from '../../components/Scoreboard/Scoreboard.js';
import { PopupContext } from '../../components/LoginPopup/LoginPopupContext.js';
import MatchNotFound from '../../components/matchData/MatchNotFound.js';
import OddsFeedComponenttt from '../../components/allsportdata/OddsFeedComponenttt.js';
import OtherBookmaker from '../../components/matchData/OtherBookmaker.js';
import Winner from '../../components/matchData/Winner.js';
import OtherMatchOdds from '../../components/matchData/OtherMatchOdds.js';
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";
import { Accordion, AccordionItem } from '@szhsin/react-accordion';
import ExchanngeinfoPopup from '../../components/matchData/ExchanngeinfoPopup.js';
import config from '../../config.js';
import betslipclosearrow from '../../assets/images/betslip-close-arrow.png'
import '../../components/matchData/matchdata.css'
import NewOddsFeedComponenttt from './NewOddsFeedComponenttt.js';
import FancyBookPopup from '../../components/matchData/Fancy/FancyBookPopup.js';
import SubNotification from '../../components/common/SubNotification.jsx';

export default function ExchangeInnerHGEvent2() {
    const { eventId } = useParams();
    let userInfo = sessionStorage.getItem('userinfo')
    const [matchStatuss, setMatchStatus] = useState(0);
    const [betPopup, setBetPopup] = useState(false);
    const [isCashOut, setCashOut] = useState(false);
    const [betresultMsg, setBetresultMsg] = useState();
    const [response, setMatchData] = useState();
    const oddsFeedRef = useRef(null);
    const [marketID, setsetMakerID] = useState([]);
    const [liveStreamSlide, setLiveStreamSlide] = useState('open');
    const [grulesPopup, setGrulesPopup] = useState();
    const [profitLossData, setProfitLossData] = useState({}); // Store dynamic data
    const [tab, setTab] = useState('all');

    const [matchOdd, setMatchOdd] = useState(null);
    
    const [allMinMax, setAllMinMax] = useState([]);
    const [myNotification, setMyNotification] = useState();
    const [stack, setStack] = useState([]);
    const [finalBetData, setFinalBetData] = useState({});
    const [isBetSlipOpen, setIsBetSlipOpen] = useState(false);
    const [multimarketArray, setMultimarketArray] = useState([]);
    const [fancyBookPopup, setFancyBookPopup] = useState()
    const { isConnected, receivedMessages, receivedMoArray,receivedBetlistData,receivedToken, sendMessage,clearCashout,receivedMinMax,receivedAllBookData,receivedcashoutMO,receivedcashoutBm,receivedBookFancy } = useSocket();
    const [betLoginShow, setBetLoginShow] = useState();
    const { loggedIn,openPopup, betUpdated, betNotUpdated, isBetUpdate,setBetLoder,unsetBetLoader,isBetLoader, loaderCounting,clearSecId ,sessionUpdate, sessionUpdatevalue,setBetOnProcess,betOnProcess,oneClickBetUpdate,stackArray} = useContext(PopupContext);
    const [count, setCount] = useState(0);
    let countIntervalId = null;
    const [myBets, setMyBets] = useState();
    const [betsData,setBetsData] = useState({
        eventId: '',
        marketId: '',
        stake: '',
        betType: '',
        selectionName: '',
        odds: '',
        rate: '',
        marketData:{}
    });

    useEffect(()=>{
        setMyBets(receivedBetlistData);
    },[receivedBetlistData, profitLossData])

    useEffect(() => {
        if (betOnProcess) {
            setCount(0);
            countIntervalId = setInterval(() => {
                setCount(prevCount => prevCount + 1);
            }, 1000);
        } else {
            setCount(0);
            clearInterval(countIntervalId);
        }

        return () => clearInterval(countIntervalId); // Cleanup on unmount
    }, [betOnProcess]);

     useEffect(() => {
            if (Object.keys(receivedcashoutMO).length > 0 && receivedcashoutMO.secId) {
                setCashOut(true);
                config.DATA = {
                    eventId: receivedcashoutMO.eventId,
                    marketId: receivedcashoutMO.marketId,
                    stake: receivedcashoutMO.stake,
                    betType: receivedcashoutMO.betType,
                    selectionName: receivedcashoutMO.runnerName,
                    odds: receivedcashoutMO.odds,
                    secId: `${receivedcashoutMO.secId}`
                }
                setBetsData({
                    eventId: receivedcashoutMO.eventId,
                    marketId: receivedcashoutMO.marketId,
                    stake: receivedcashoutMO.stake,
                    betType: receivedcashoutMO.betType,
                    selectionName: receivedcashoutMO.runnerName,
                    odds: receivedcashoutMO.odds,
                    secId: `${receivedcashoutMO.secId}`
                });                
            }
            // console.log(multimarketArray,"-------multimarketArray");
        }, [receivedcashoutMO]);

        useEffect(() => {
            if (Object.keys(receivedcashoutBm).length > 0 && receivedcashoutBm.secId) {
                setCashOut(true);
                config.DATA = {
                    eventId: receivedcashoutBm.eventId,
                    marketId: receivedcashoutBm.marketId,
                    stake: receivedcashoutBm.stake,
                    betType: receivedcashoutBm.betType,
                    selectionName: receivedcashoutBm.runnerName,
                    odds: receivedcashoutBm.odds,
                    secId: `${receivedcashoutBm.secId}`
                }
                setBetsData({
                    eventId: receivedcashoutBm.eventId,
                    marketId: receivedcashoutBm.marketId,
                    stake: receivedcashoutBm.stake,
                    betType: receivedcashoutBm.betType,
                    selectionName: receivedcashoutBm.runnerName,
                    odds: receivedcashoutBm.odds,
                    secId: `${receivedcashoutBm.secId}`
                });                
            }
            // console.log(receivedcashoutBm,"-------receivedcashoutBm");
        }, [receivedcashoutBm]);

    const updateBook = (oldStack, newStack) => {
        if (betsData.marketData && Object.keys(betsData.marketData).length > 0 ) {
            let newvalue = newStack;
            let oldvalue = oldStack;
            
            let oddsValue = betsData.odds;
            let currentRunnerID = betsData.secId;
            let currentMakertID = betsData.marketId;
            let currentRunnerPL = profitLossData[`${currentMakertID}_${currentRunnerID}`]
            let otehrRunnerID = betsData.marketData?.runners?.filter(item => item.runnerId != betsData.secId) || [];
            let addValueCurrentRunn = 0
            let addvalueOther = 0
            let diff = 0
            let marketType = betsData.marketData.marketType
            console.log(oldStack, newStack, betsData.betType,betsData.marketData.marketType);

            if(betsData.betType === config.back){
                if(newStack > oldStack){
                    console.log('gfgfgf');
                    diff = Math.abs(newStack - oldStack)
                    if(marketType == 'BOOKMAKER'){
                        addValueCurrentRunn = (diff * oddsValue) / 100
                        addvalueOther = - diff
                    }else{
                        addValueCurrentRunn = (diff * oddsValue) - diff
                        addvalueOther = - diff
                    }
                }else if(newStack < oldStack){
                    diff = Math.abs(oldStack-newStack)
                    if(marketType == 'BOOKMAKER'){
                        addValueCurrentRunn = - Math.abs((diff * oddsValue) / 100)
                        addvalueOther =  diff
                    }else{
                        addValueCurrentRunn = - Math.abs((diff * oddsValue) - diff)
                        addvalueOther = diff
                    }

                }
                
            }else{
                if(newStack > oldStack){
                    diff = Math.abs(newStack - oldStack)
                    if(marketType == 'BOOKMAKER'){
                        addValueCurrentRunn =  - diff
                        addvalueOther = (diff * oddsValue) / 100
                    }else{
                        addValueCurrentRunn =  - diff
                        addvalueOther = (diff * oddsValue) - diff
                    }
                }else if(newStack < oldStack){
                    diff = Math.abs(oldStack-newStack)
                    if(marketType == 'BOOKMAKER'){
                        addValueCurrentRunn =   diff
                        addvalueOther =  - Math.abs((diff * oddsValue) / 100)
                    }else{
                        addValueCurrentRunn = diff
                        addvalueOther = - Math.abs((diff * oddsValue) - diff)
                    }

                }
            }
            updateProfit(betsData.marketId,currentRunnerID,addValueCurrentRunn)
            for (let i = 0; i < otehrRunnerID.length; i++) {
                const element = otehrRunnerID[i].runnerId;
                updateProfit(betsData.marketId,element,addvalueOther)
            }
        }    
    }

    let isOneClicked = sessionStorage.getItem('isOneClicked')
    let oneClickValue
    if(isOneClicked == 'true'){
        oneClickValue = sessionStorage.getItem('oneClickAmount')
    }

    useEffect(()=>{
        if (receivedAllBookData) {
            setProfitLossData([]);
            if (Array.isArray(receivedAllBookData.showData) && receivedAllBookData.showData.length > 0) {
                receivedAllBookData.showData.forEach(marketData => {                    
                    Object.entries(marketData).forEach(([marketId, runners]) => {
                        runners.forEach(({ runnerId, amount }) => {
                            updateProfitLoss(marketId, runnerId, amount);
                        });
                    });
                });
            }
        }
        
    },[receivedAllBookData])

    useEffect(()=>{
        isOneClicked = sessionStorage.getItem('isOneClicked')
        oneClickValue = sessionStorage.getItem('oneClickAmount')
    },[oneClickBetUpdate])

    useEffect(() => {
            try{
                setStack(JSON.parse(stackArray));
            }catch(err){
                setStack(stackArray)
            }
        }, [stackArray]);

    const checkGameStart = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        if(sessionStorage.getItem('loggedIn') || loggedIn){
          myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
        }

       const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/matchStatus?eventId=${eventId}`, requestOptions)
          .then(async (response) => {
            let paresData = await response.json();
            if(response.ok){
                setMatchStatus(paresData?.matchStatus === 'IN_PLAY' || paresData?.matchStatus === 'UPCOMING' ? 1 : 2)
            }else{
                setMatchStatus(2)
            }
          })
          .catch((error) => {
            console.log(error);
          });
    };

    const updateProfitLoss = (marketId, runnerId, value) => {
        setProfitLossData(prev => ({
            ...prev,
            [`${marketId}_${runnerId}`]: formatNumberrr(value) // Store data for each marketId & runnerId
        }));
    };

    const updateProfit = (marketId, runnerId, value) => {
        setProfitLossData(prev => {
            const key = `${marketId}_${runnerId}`;
            const existingValue = prev[key] ? parseFloat(prev[key]) : 0; // Get existing value or default to 0
            return {
                ...prev,
                [key]: formatNumberrr(existingValue + value) // Add to existing value
            };
        });
    };

    const formatNumberrr = (num) => {
        const str = num.toString();
        const dotIndex = str.indexOf(".");
    
        // If there is no decimal point, return as is
        if (dotIndex === -1) return num;
    
        // Extract up to two decimal places without rounding
        return str.substring(0, dotIndex + 3);
    };

    const fetchData = (id,mId) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
    
        fetch(
          `${process.env.REACT_APP_BASE_URL}/api/v1/games/getInPlayMatchData?id=${id}`,
          requestOptions
        )
          .then(async (response) => {
            let paresData = await response.json();
            setMatchData(paresData);
          })
          .catch((error) => {
            console.log(error);
          });
    };

    useEffect(() => {
        if (!response || !response.matchData || !response.matchData.markets) return;
        console.log(response,"responseresponseresponseresponseresponse");
        
        const { markets } = response.matchData;
        let mo = markets.matchOdds?.filter((item) => item.marketType === "MATCH_ODDS" || item.marketType === "TOURNAMENT_WINNER" || item.marketType === "WIN");
        
        const moIds = mo.map((item) => item.marketId);

        setsetMakerID(moIds);
        setMatchOdd(mo);
        console.log(mo,"aaaaaaaaaaaaaaaaaaaaaa");
        
        // if (b5) { addMarketID(b5.marketId) }

      }, [response]); // Updates every time `response` changes

    useEffect(() => {
        setsetMakerID([]);
        fetchData(eventId);
        getSubNotification(eventId);
        checkGameStart();
        console.log(eventId,"");
        
    }, [eventId]);

    const publicKeyPEM = `-----BEGIN PUBLIC KEY-----
    MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAKik5c/NUnfRBlOR6fgoSwG5YMEgclwe
    BBcPrHR452svxh9lPPNUr8stKqglGU40+ilIzXlsKlZ1sMKh4w6+O6MCAwEAAQ==
    -----END PUBLIC KEY-----`;

    // Generate AES key
    const generateAESKey = () => {
        return CryptoJS.lib.WordArray.random(16).toString(CryptoJS.enc.Hex); // 16-byte key
    };

    // Encrypt with AES
    const encryptWithAES = (text, key) => {
        return CryptoJS.AES.encrypt(text, key).toString();
    };

    // Encrypt AES Key with RSA
    const encryptAESKeyWithRSA = (aesKey) => {
        return new Promise((resolve, reject) => {
            const encryptor = new JSEncrypt();
            encryptor.setPublicKey(publicKeyPEM);
            const encryptedKey = encryptor.encrypt(aesKey);
            
            if (encryptedKey) {
                resolve(encryptedKey);
            } else {
                reject("RSA Encryption failed!");
            }
        });
    };
    
    // Main function
    const encryptLargeMessage = async (message) => {
        try {
            const aesKey = generateAESKey(); // Step 1: Generate AES Key
            const encryptedMessage = encryptWithAES(message, aesKey); // Step 2: Encrypt Message
            
            // Step 3: Encrypt AES Key with RSA (using await)
            const encryptedAESKey = await encryptAESKeyWithRSA(aesKey);
    
            return {
                encryptedMessage,
                encryptedAESKey
            };
        } catch (error) {
            console.error("Encryption failed:", error);
        }
    };

    const sendBetList = () =>{
        sendMessage('getbetlistdata', betlistData);
    }

    useEffect(() => {
        const encryptData = async () => {
            let final = {};
            if (receivedToken.token) {
                console.log(receivedToken.token, "receivedTokenreceivedTokenreceivedToken");
                let user = JSON.parse(sessionStorage.getItem('userinfo'));
                let string = `${user._id}_${receivedToken.token}_${user.userName}`;
                // Encrypt data
                const enc = await encryptLargeMessage(string);
                let msg = `${enc.encryptedAESKey}_token_${enc.encryptedMessage}`
                let aaa = isOneClicked === "true" ? config.DATA : betsData
                if (!isCashOut) {
                    if (isOneClicked === "true") {
                        aaa.stake = oneClickValue
                    }
    
                    final = {
                        ...aaa,    // If there's any existing data, retain it
                        msg: msg , // Add the new token
                    };
                }else{
                    let asd = config.DATA
                    final = {
                        ...asd,    // If there's any existing data, retain it
                        msg: msg , // Add the new token
                    };
                }

                try {
                    // Replace 'your-token' with your actual token
                    const myHeaders = new Headers();
                    myHeaders.append("Content-Type", "application/json");
                    myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
                    console.log("request :::::: ", final)
                    const requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        redirect: "follow",
                        body: JSON.stringify(final)
                      };
        
                      fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/bets/placeBet`, requestOptions)
                      .then(async (response) => {  
                        if (response.ok) {
                            let result = await response.json();
                            sendBetList();
                            setBetPopup(true);
                            setBetOnProcess(false);
                            clearInterval(countIntervalId);
                            setBetresultMsg(result);
                            setFinalBetData({});
                            handleResetBtn();    
                            config.DATA = {}    
                            SendBookDetails_MB();
                            setCashOut(false);
                            const betResultpopupTimeer = setTimeout(() => {
                                setBetPopup(false);
                            }, 1500);
                            return () => {
                                clearTimeout(betResultpopupTimeer);
                            }
                        } else{
                            let errorResult = await response.json(); // Parse the JSON error response
                            sendBetList();
                            setBetPopup(true);
                            setBetresultMsg(errorResult);
                            setFinalBetData({});
                            setBetOnProcess(false);
                            clearInterval(countIntervalId);
                            config.DATA = {};
                            handleResetBtn();
                            setCashOut(false);
                            SendBookDetails_MB();
                            const betResultpopupTimeer = setTimeout(() => {
                                setBetPopup(false);
                            }, 1500);
                            return () => {
                                clearTimeout(betResultpopupTimeer);
                            }
                        }
                        
                      })
                      .catch(async (error) => {
                        sendBetList();
                        SendBookDetails_MB();
                        setFinalBetData({});
                        setCashOut(false);
                        setBetOnProcess(false)
                        clearInterval(countIntervalId);
                        config.DATA = {};
                        handleResetBtn();
                        console.log(error);
                        SendBookDetails_MB();
                      });
        
                } catch (error) {
                    sendBetList();
                    SendBookDetails_MB();
                    setBetOnProcess(false)
                    clearInterval(countIntervalId);
                    setFinalBetData({});
                    SendBookDetails_MB();
                    setCashOut(false);
                    handleResetBtn();
                    console.error('Error fetching API data:', error);
                }
            }
        };
        encryptData();
    }, [receivedToken])

    useEffect(()=>{
        setAllMinMax(receivedMinMax.minmaxdata);
        // console.log(receivedMinMax,"receivedMinMaxreceivedMinMaxreceivedMinMax");
    },[receivedMinMax])

    // Effect for 'getmarketminmaxdata'
    useEffect(() => {
        if (!isConnected || !response) return;

        SendBookDetails_MB(); // Call once when dependencies change

        const sendMarketUpdates = () => {
            let data = {
                userId: '64db25fa1617ac95db367a0d',
                marketIds: marketID,
                eventId: eventId
            };
            sendMessage('getmarketminmaxdata', data);
        };

        sendMarketUpdates(); // Send immediately
        const intervalId = setInterval(sendMarketUpdates, 3000);

        return () => {clearInterval(intervalId)};
    }, [response, isConnected, marketID]);

    const getMyMinMax =(id) =>{        
        if (allMinMax && allMinMax.length > 0) {
            let data = allMinMax.find(item=>item.marketId === id);
            return `Min: ${data?.min_stake ? data?.min_stake :'0'}, Max: ${data?.max_stake ? data?.max_stake : "0"}`;    
        }
    }

    const SendBookDetails_MB = () => {
        let userinfo = sessionStorage.getItem('userinfo');
        if (userinfo && eventId) {
            userinfo = JSON.parse(userinfo);
            sendMessage('marketIdbookDetailsByEventId', { eventId, user: userinfo });
        } else if(!userinfo){
            sendMessage('marketIdbookDetailsByEventId', { eventId });
        }else{
            console.error('Missing eventId or marketId');
        }
    };

    useEffect(()=>{
        if (sessionStorage.getItem('userinfo') || loggedIn) {
            setBetLoginShow(true)
        } else {
            setBetLoginShow(false)
        }
    })

    const liveStreamHandle = () => {
        setLiveStreamSlide(!liveStreamSlide)
    }

    const grulesHandle = (e)=>{
        e.stopPropagation();
        setGrulesPopup(true);
    }
    const gruleshandleClose = ()=>{
        setGrulesPopup(false);
    }
    const subtractValue = (event) => {
        const value = betsData.stake <= 100 ? 0 : betsData.stake - 100;
        updateBook(betsData.stake,value)
        setBetsData(prev => ({ ...prev, stake: value }));
    }

    const PlusValue = (event) => {
        const value = betsData.stake + 100;
        updateBook(betsData.stake,value)
        setBetsData(prev => ({ ...prev, stake: value }));
    }

    const handleIncrementOdds = (event) => {
        let newOdds =(parseFloat(betsData.odds || "0") + 0.01).toFixed(2)
        setBetsData(prevState => ({
            ...prevState,
            odds: newOdds
        }));
    };

    const handleDecrementOdds = (event) => {
        let newOdds =(parseFloat(betsData.odds || "0") - 0.01).toFixed(2)
        setBetsData(prevState => ({
            ...prevState,
            odds: newOdds
        }));
    };

    const addAllValue = (item) => {
        updateBook(betsData.stake,item)
        setBetsData(prev => ({
            ...prev, 
            stake: (item)
        }))
    }

    const allBlancce = () => {
        let userinfo = sessionStorage.getItem('balance');
        if (userinfo !== null) {
            let data = JSON.parse(userinfo);
            return (data.availableBalance - data.totalExposure).toFixed(2);
        }else{
            return 0;
        }
    };

    const getMaxOdds = (id) =>{
        if (allMinMax && allMinMax.length > 0) {
            let data = allMinMax.find(item=>item.marketId === id);
            return data?.max_odd;    
            }else{
                return 0;
            }
    }

    const isMarketIdPresent = (multimarketArray, marketId) => {
        return multimarketArray.includes(marketId);
    };

    const mutliMarketAddRemove =(marketID,evenID) =>{
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);

        const raw = JSON.stringify({
            marketId: marketID,
            eventId: evenID
        });

        const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
        };

        fetch(`${process.env.REACT_APP_BASE_URL}/armultimarket`, requestOptions)
        .then(async (response) => {
            let paresData = await response.json();
            if(response.ok){
                setMultimarketArray(paresData.marketId)
            }
          })
          .catch((error) => {
            console.log(error.message);
          });
    }

    const validation = (price) => {
        if(price &&
            price !== '-' &&
            price !== 0){
                return true
            }else{
                return false
            }
    } 

    const formatNumber = (num) => {
        if (num >= 1000000000) {
          return (num / 1000000000).toFixed(2) + 'B'; // Billion
        } else if (num >= 1000000) {
          return (num / 1000000).toFixed(2) + 'M'; // Million
        } else if (num >= 1000) {
          return (num / 1000).toFixed(2) + 'K'; // Thousand
        } else {
          return num; // Less than 1000
        }
    };

    const getBoxValue = (runner) =>{
        return <>
        <strong>{validation(runner.price) ? runner.price : getLock()}</strong>
        <small>
        {validation(runner.size) ? formatNumber(runner.size) : '' }
        </small>
    </>
    }

    const checkAdminIsSuspend = (id) =>{
        if (allMinMax && allMinMax.length > 0) {
            let data = allMinMax.find(item=>item.marketId === id);
            return data?.resumesuspendstatus;    
            }else{
                return false;
            }
    }

    const checkMaxOdds = (id,price) =>{
        if (allMinMax && allMinMax.length > 0) {
            let data = allMinMax.find(item=>item.marketId === id);
            return data?.max_odd < price;    
            }else{
                return false;
            }
    }

    const isSuspendedMatchOdds = (runner,matchOdd) => { 
        if (!checkAdminIsSuspend(matchOdd.marketId)) {
            if (matchOdd.status === "OPEN") {
                if (runner.status !== "ACTIVE") {
                    return true;
                } else {
                    return false;
                }
            }else{
                return true;
            }
        }else{            
            return true;
        }
    }
    const getLock = () => {
        return <i className="fa fa-lock"></i>
    }

    const getSuspendedMatchOdds = (status,matchOdd) => {    
        // console.log(status,matchOdd.status,checkAdminIsSuspend(matchOdd.marketId),"121212121212121212121212");     
        if (!checkAdminIsSuspend(matchOdd.marketId)) {
            if (matchOdd.status == "OPEN") {
                if (status != "ACTIVE") {
                    return <div className="suspended">{status}</div>
                }
            }else{
                return <div className="suspended">{matchOdd.status}</div>
            }
        }else{
            return <div className="suspended">Suspended</div>
        }
    }

    const getSubNotification = (ids) => {
        let data = JSON.stringify({
            "eventId":ids
        })
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
    
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: data,
        };
    
        fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/notification/getmarketNotitfication`, requestOptions)
          .then(async (response) => {
            let paresData = await response.json();
            setMyNotification(paresData?.notifications);
          })
          .catch((error) => {
            console.log(error);
          });
      }

    const isCommission = (id) => {
        if (allMinMax && allMinMax.length > 0) {
            let data = allMinMax.find(item=>item.marketId === id);
            return data?.commissionstatus
        }else{
            return false;
        }
    }

    const handleCashOutMO = () => {
        let marketId = matchOdd?.marketId;
        let userinfo = sessionStorage.getItem('userinfo');
        let eventID = eventId;
        if (userinfo && eventID) {
            userinfo = JSON.parse(userinfo);
            sendMessage('cashOOut', { id: marketId, user: userinfo, eventID });
        }else{
        }
    };


    const handlePlceBets = () => {
        setBetOnProcess(true);
        let user = JSON.parse(sessionStorage.getItem('userinfo'));
        sendMessage("createToken",{userName:user.userName});
    }

    const getBetSlip = (runner) => {          
        let id;
        let compareId;
        if (runner.marketType === "FANCY"){
            id = runner.marketId;
            compareId = betsData.marketId;
        }else{
            id = runner.runnerId;
            compareId = betsData.secId;
        }
        return <div className={`betslip-wraper ${compareId == id ? 'open':''}`} id={id} key={id}>
            <div className={`betslip active ${betsData.betType === config.back ? 'back' : 'lay'}`}>
                <div className="close-btn" onClick={handleResetBtn}>×</div>
                <div className="betslip-top">
                <div className="bet-selection-name" >Bet on : {betsData.selectionName} @{betsData.odds}</div>
                <div className="bet-profit"></div>
                <div className="betslip-odds betslip-stake">
                    <strong>Odds</strong>
                    <div className='input-group'>
                        <span onClick={(event) => handleDecrementOdds(event)}>-</span>
                        <input type="text" value={betsData.odds} />
                        <span onClick={(event) => handleIncrementOdds(event)}>+</span>
                    </div>
                </div>
                <div className="betslip-stake">
                    <strong>Stake</strong>
                    <div className="input-group">
                        <span className="minus" onClick={(event) => subtractValue(event)}>-</span>
                        <input type="text" value={betsData.stake} aria-label="Last name" autoFocus="" maxLength="8" onChange={(e) =>  (updateBook(betsData.stake,e.target.value),setBetsData(prev => ({ ...prev, stake: e.target.value })))} />
                        <span className="plus" onClick={(event) => PlusValue(event)}>+</span>
                    </div>
                </div>
                </div>
                <div className="betslip-placebutton">
                    <div className="price-btns">
                        {Array.isArray(stack) && stack.map((item,index) => (
                            <span key={index} id={`${item}`} onClick={()=> addValue(item)}>{formatNumberinStack(item)}</span>
                        )) }
                        <span id="1000" className="MAX" onClick={()=>addAllValue(getMaxOdds(betsData.marketId))} >MAX</span>
                        <span id="1000" className="MAX" onClick={()=>addAllValue(allBlancce())}>All</span>
                    </div>
                    <div className="betplace-btn">
                        {sessionStorage.getItem('token') || loggedIn ? <button className="place-bet" onClick={handlePlceBets} >Place Bet</button> : 
                        <button className="login-btn" onClick={openPopup}>Login to continue</button>
                        }
                    </div>
                </div>
            </div>

            <div className={`betslip_mobileview active ${betsData.betType === config.back ? 'back' : 'lay'}`}>
                <div className="close-btn" onClick={handleResetBtn}>×</div>
                <table className="eventdetails">
                    <tbody>
                        <tr>
                            <td>
                                <div className='plus-minus-wraper'>
                                    <div className='plus-minus'>
                                        <span onClick={(event) => handleDecrementOdds(event)}>-</span>
                                        <input type="text" value={betsData.odds} />
                                        <span onClick={(event) => handleIncrementOdds(event)}>+</span>
                                    </div>
                                    <div className='plus-minus'>
                                        <span onClick={(event) => subtractValue(event)}>-</span>
                                        <input type="text" value={betsData.stake} aria-label="Last name" autoFocus="" maxLength="8" onChange={(e) =>  (updateBook(betsData.stake,e.target.value),setBetsData(prev => ({ ...prev, stake: e.target.value })))} />
                                        <span onClick={(event) => PlusValue(event)}>+</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                        <td colSpan="11" className='mbet-slip'>
                            <div className="mbet-slip-wraper">
                                {Array.isArray(stack) && stack.map((item,index) => (
                                    <div key={index} id={`${item}`} className="odds-value" onClick={()=> addValue(item)}>{formatNumberinStack(item)}</div>
                                )) }
                                <div className="odds-value max" onClick={()=>addAllValue(getMaxOdds(betsData.marketId))} id="10000">MAX</div>
                                <div className="odds-value all" onClick={()=>addAllValue(allBlancce())} id="98941.80">ALL IN</div>
                            </div>
                        </td>
                        </tr>
                        <tr className='numbering'>
                        <td colSpan="11">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><span id="1" onClick={()=>addValueNumber('1')}>1</span></td>
                                        <td><span id="2" onClick={()=>addValueNumber('2')}>2</span></td>
                                        <td><span id="3" onClick={()=>addValueNumber('3')}>3</span></td>
                                        <td><span id="4" onClick={()=>addValueNumber('4')}>4</span></td>
                                        <td><span id="5" onClick={()=>addValueNumber('5')}>5</span></td>
                                        <td><span id="6" onClick={()=>addValueNumber('6')}>6</span></td>
                                        <td rowSpan="2"><span className="back-span" onClick={removeLastDigit}><img alt="" src={betslipclosearrow}/></span></td>
                                    </tr>
                                    <tr>
                                        <td><span id="7" onClick={()=>addValueNumber('7')}>7</span></td>
                                        <td><span id="8" onClick={()=>addValueNumber('8')}>8</span></td>
                                        <td><span id="9" onClick={()=>addValueNumber('9')}>9</span></td>
                                        <td><span id="0" onClick={()=>addValueNumber('0')}>0</span></td>
                                        <td><span id="double0" onClick={()=>addValueNumber('00')}>00</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        </tr>
                        <tr className="bet-btns">
                            <td colSpan="11">
                                <div className="btn-wraper">
                                    <button className="btn cancel-bet" onClick={handleResetBtn}>CANCEL</button>
                                        {sessionStorage.getItem('token') || loggedIn ? 
                                        <button className="btn place-bet" onClick={handlePlceBets}>Place Bet</button> :
                                        <button className="btn login-btn" onClick={openPopup}>Login to continue</button>}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {betOnProcess ? <div className="loader-sec loading">
                Placing Your Bet, Please Wait <div className='loader-box'><span className='loading-number'>{count}</span><span className='loader'></span></div>
            </div> :'' }
            
        </div>
    }

    const handleResetBtn = () => {
        SendBookDetails_MB();
        setIsBetSlipOpen(false);
        setBetsData({
            eventId: '',
            marketId: '',
            stake: 0,
            betType: '',
            selectionName: '',
            odds: '',
            secId: '',
            marketData:{}
        });
    }

    const removeLastDigit = () => {
        setBetsData(prev => ({
            ...prev,
            stake: prev.stake.toString().slice(0, -1) // Removes the last character
        }));
    };

    const addValue = (item) => {
        updateBook(betsData.stake,item+betsData.stake)
        setBetsData(prev => ({
            ...prev, 
            stake: (parseFloat(prev.stake) + item)
        }))
    }

    const addValueNumber = (item) => {
        updateBook(betsData.stake, betsData.stake.toString() + item.toString());
        setBetsData(prev => ({
            ...prev,
            stake: prev.stake.toString() + item.toString() // Concatenate instead of adding
        }));
    };
    

    const formatNumberinStack = (num) => {
        if (num >= 1000000000) {
          return (num / 1000000000) + 'B'; // Billion
        } else if (num >= 1000000) {
          return (num / 1000000) + 'M'; // Million
        } else if (num >= 1000) {
          return (num / 1000) + 'K'; // Thousand
        } else {
          return num; // Less than 1000
        }
      };

      const handleOnBackClick = (runner,betType,marketId,odds,isFancy,marketType,matchOdd) => {
        if (!betOnProcess){
            setIsBetSlipOpen(true);
            if (isFancy === config.isNotfancy) {
                config.DATA = {
                    eventId: eventId,
                    marketId: marketId,
                    stake: 0,
                    betType: betType,
                    selectionName: runner.runnerName,
                    odds: odds,
                    secId: `${runner.runnerId}`,
                    marketData:matchOdd
                }
                setBetsData({
                    eventId: eventId,
                    marketId: marketId,
                    stake: 0,
                    betType: betType,
                    selectionName: runner.runnerName,
                    odds: odds,
                    secId: `${runner.runnerId}`,
                    marketData:matchOdd
                }); 
            }else{
                config.DATA = {
                    eventId: eventId,
                    marketId: marketId,
                    stake: 0,
                    betType: betType,
                    selectionName: runner.marketName,
                    odds: config.back ? runner.yesRate : runner.noRate,
                    rate: betType === config.back ? runner.yesValue : runner.noValue
                }
                setBetsData({
                    eventId: eventId,
                    marketId: marketId,
                    stake: 0,
                    betType: betType,
                    selectionName: runner.marketName,
                    odds: config.back ? runner.yesRate : runner.noRate,
                    rate: betType === config.back ? runner.yesValue : runner.noValue
                }); 
            }
            if (isOneClicked === "true") {
                setBetOnProcess(true);
                let user = JSON.parse(sessionStorage.getItem('userinfo'));
                sendMessage("createToken",{userName:user.userName});
            }
        }else{
            console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaa");
            
        }
    }

    const setProfitloss = (marketId,runnerId) => {
        return <span className={`${ profitLossData[`${marketId}_${runnerId}`] > 0 ? "green-txt" : profitLossData[`${marketId}_${runnerId}`] < 0 ? "red-txt" : "" } book-value`}>{profitLossData[`${marketId}_${runnerId}`] || ""}</span>
    }

    let matchDataAccess = {
        "eventId": eventId,
        "userId": process.env.REACT_APP_ENV === 'development' ? '64db25fa1617ac95db367a0d' : '65fc48e7eab864a79776c01c'
    };
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        matchDataAccess = {
            "eventId": eventId,
            "userId": userInfo._id
        };
  
    }
    const [betlistData, setBetlistData] = useState(matchDataAccess);
    
    useEffect(() => {
        sendMessage('getbetlistdata', betlistData); 
        const intervalId = setInterval(() => {
            sendMessage('getbetlistdata', betlistData);
        }, 5000);
        return () => {clearInterval(intervalId);}
    }, [response,isConnected]);

    const getMatchOddsHandle = (data) => {
        if (data && matchOdd?.length) {  
            const updatedMatchOdds = matchOdd.map((item) =>
                item.marketId === data.marketId ? data : item
            );
            setMatchOdd(updatedMatchOdds);
        }
    };
    

    const getId = (data, position) => {
        if (!Array.isArray(data) || data.length === 0) {
          return "Invalid Data"; // Case: data is not an array or is empty
        }
      
        const item = data[position]; // Get the item at the given position
      
        if (!item || item === null) {
          return "N/A"; // Case: data[position] is null or undefined
        }
      
        return item.marketId || "N/A"; // Case: marketId is missing or falsy
    };

    const getUnderOverHandle = () => {
        
    }

    const getNotification = (id) =>{
        return myNotification && myNotification.length > 0 && <SubNotification isFancy={false} msg={myNotification && myNotification.find(noti => noti.marketId == id)?.notifications} />
    } 

    return (
        <>
            {response && <NewOddsFeedComponenttt ref={oddsFeedRef} eventId={eventId} eventAccess={response} getMatchOddsHandle={getMatchOddsHandle} getUnderOverHandle={getUnderOverHandle} getBookmakerHandle={getUnderOverHandle} getFancyHandle={getUnderOverHandle} />}
            <Container size='full'>
            {matchStatuss && matchStatuss === 1 ?
                <div className='content-w-sidebar exchange-inner-page'>
                    <div className='left-content'>
                        <Scoreboard eventId={eventId} scoreboardData={response?.matchData} receivedBetlistData={receivedBetlistData} />
                        <div className='match-data-wraper'>
                        
                        { matchOdd && matchOdd?.length > 0 && matchOdd.map((match,matchIndex)=>(
                            <div key={matchIndex} className='sport-all-data inner-match-list'>
                                <div className='match-wrapper'>
                                <Accordion transition transitionTimeout={350}>
                                    <AccordionItem initialEntered header={
                                        <div className={`heading`}>
                                            <div className='heading-text sport-name'>
                                            {match?.marketName}{isCommission(match.marketId) ? <span className='blink'>*</span> :''} <span className='rulespopup-btn' onClick={(e)=>grulesHandle(e)}><i className="fa-solid fa-circle-info"></i></span>
                                            </div>
                                            <div className='heading-text sport-name'>
                                              {getMyMinMax(match?.marketId)}
                                            </div>
                                            </div>}>

                                            <div className='heading match-heading'>
                                            <div className='heading-text sport-name'>
                                                Market
                                            </div>
                                           
                                            <div className='odds-heading'>
                                                <div className='heading-text box blue'>Back</div>
                                                <div className='heading-text box pink'>Lay</div>
                                            </div>
                                            </div>
                                            <div className='match-data'>
                                            {match?.runners?.length > 0 ? (
                                                match.runners.map((runner, index) => (
                                                    <div className='data-with-betslip' key={index} id={runner.runnerId}>
                                                    <div className='match-info-row'>

                                                    {response?.matchData?.sportId == '7' ? 
                                                        <div className="title-w-popover">
                                            <div className="horse-number">
                                            <span>{runner?.metadata?.CLOTH_NUMBER_ALPHA}</span>
                                            <span>({runner?.metadata?.STALL_DRAW})</span>
                                            </div>
                                            {runner?.metadata?.SIRE_NAME?
                                            <div className="icon"><img className="w-90" src={`https://${window.location.hostname}/images/tshirt.png`} height="28px" width="28px" alt=""/></div>
                                            :<div className="icon"></div>}
                                            <div className="title runnerName-div">  {runner.runnerName} <span>{runner?.metadata?.JOCKEY_NAME}</span> </div>
                                            <div className="popover">
                                                <ul className="popover-list">
                                                    <li><strong>Trainer</strong>{runner?.metadata?.TRAINER_NAME}</li>
                                                    <li><strong>Age/Weight</strong>{runner?.metadata?.AGE}</li>
                                                    <li><strong>Form</strong>{runner?.metadata?.FORM}</li>
                                                    <li><strong>Days since last run</strong>{runner?.metadata?.DAYS_SINCE_LAST_RUN}</li>
                                                    {runner?.metadata?.JOCKEY_CLAIM
                                                        ?<li><strong>Jockey claim</strong>{runner?.metadata?.JOCKEY_NAME}</li>
                                                        :<li><strong>Jockey claim</strong>-</li>
                                                    }
                                                        {runner?.metadata?.WEARING
                                                            ?<li><strong>Wearning</strong>{runner?.metadata?.WEARING}</li>
                                                            :<li><strong>Wearning</strong>-</li>
                                                        }

                                                </ul>
                                            </div>
                                            </div>: <div className='match-name'>{runner.runnerName}</div>}
                                                    

                                                        
                                                        {setProfitloss(match?.marketId,runner.runnerId)}
                                                        <div className="match-odds">
                                                                <div className="box">
                                                                    <span className={`blue ${checkMaxOdds(match?.marketId,runner.backPrices?.[2]?.price ?? 0) ? 'disable':''}`} onClick={()=>handleOnBackClick(runner,config.back,match.marketId, runner.backPrices?.[0]?.price,config.isNotfancy,config.MATCH_ODDS,match)}> {!isSuspendedMatchOdds(runner,match) ? (validation(runner.backPrices?.[2]?.price) ? getBoxValue(runner.backPrices?.[2]) : getLock()) :getLock() }</span>
                                                                    <span className={`blue ${checkMaxOdds(match?.marketId,runner.backPrices?.[1]?.price ?? 0) ? 'disable':''}`} onClick={()=>handleOnBackClick(runner,config.back,match.marketId, runner.backPrices?.[0]?.price,config.isNotfancy,config.MATCH_ODDS,match)}> {!isSuspendedMatchOdds(runner,match) ? (validation(runner.backPrices?.[1]?.price) ? getBoxValue(runner.backPrices?.[1]) : getLock()) :getLock() }</span>
                                                                    <span className={`blue ${checkMaxOdds(match?.marketId,runner.backPrices?.[0]?.price ?? 0) ? 'disable':''}`} onClick={()=>handleOnBackClick(runner,config.back,match.marketId, runner.backPrices?.[0]?.price,config.isNotfancy,config.MATCH_ODDS,match)}> {!isSuspendedMatchOdds(runner,match) ? (validation(runner.backPrices?.[0]?.price) ? getBoxValue(runner.backPrices?.[0]) : getLock()) :getLock() } </span>
                                                                </div>
                                                                <div className="box">
                                                                    <span className={`pink ${checkMaxOdds(match?.marketId,runner.layPrices?.[0]?.price ?? 0) ? 'disable':''}`}>{getLock()}</span>
                                                                    <span className={`pink ${checkMaxOdds(match?.marketId,runner.layPrices?.[1]?.price ?? 0) ? 'disable':''}`}>{getLock()}</span>
                                                                    <span className={`pink ${checkMaxOdds(match?.marketId,runner.layPrices?.[2]?.price ?? 0) ? 'disable':''}`}>{getLock()}</span>
                                                                </div>
                                                                {getSuspendedMatchOdds(runner.status,match)}
                                                            </div>
                                                    </div>
                                                    {getBetSlip(runner)}
                                                    </div>
                                                ))
                                            ):(<p>Loading Match Odds market data...</p>)}
                                            {getNotification(match?.marketId)}
                                            </div>

                                                                            </AccordionItem>
                                </Accordion>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>

                    {response ?
                    <div className='right-content sidebar'>
                        {response?.matchData && response?.matchData.status == 'IN_PLAY' ?
                        <div className='sidebar-box live-stream'>
                            <div className='title live-stream-title' onClick={liveStreamHandle}>Live Stream</div>
                            <div className={`livestream-iframe ${liveStreamSlide && liveStreamSlide ? 'open':'' }`}>
                                <iframe src={`https://tv-quq1tgjxfpeu.fstlive.video/?eventid=${eventId}`} ></iframe>
                            </div>
                        </div> 
                        : ''
                        }
                        <div className='sidebar-box'>
                            <div className='title'>Open Bets ({receivedBetlistData?.bets?.length})</div>
                            {receivedBetlistData?.bets?.length > 0 ?
                                <div className='betlist'>
                                    {betLoginShow ?
                                        <table className="betlist-table">
                                            <thead>
                                                <tr>
                                                    <th>Selection</th>
                                                    <th>Odds</th>
                                                    <th>Market</th>
                                                    <th>Stake</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {receivedBetlistData?.bets?.map((item, index) => (
                                                    <tr key={index} className={`${item?.bettype2 === 'BACK' ? 'back' : 'lay'}`}>
                                                        <td> {item?.selectionName}</td>
                                                        <td> {item?.oddValue}</td>
                                                        <td> {item?.marketName}</td>
                                                        <td> {item?.Stake}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table> : ''}
                                </div> : ''}
                        </div>
                    </div> : <div className='sidebar skeleton'></div>
                    }
                </div>
                :
                <div className={`${matchStatuss === 0 ? 'hide' : matchStatuss === 1 ? 'hide' : 'show'}`}> <MatchNotFound />  </div>}

            </Container>
            <Popup popupClass={`alertMsgpopup ${betPopup ? 'open' : ''}`} content={<div className={`alert-msg ${betresultMsg?.status === 'Error' ? 'err' : ''}`}> <span className='msg'>{betresultMsg?.status === 'success' ? betresultMsg?.msg : betresultMsg?.message}</span><div className="alert-progress-bar"></div> </div>} />
            <ExchanngeinfoPopup isOpen={grulesPopup} handleClose={gruleshandleClose} />
        </>
    )
}