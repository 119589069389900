import React, { useEffect, useRef, useState } from 'react';
import config from '../../config';

const NewOddsFeedComponenttt = ({ eventId, eventAccess, getMatchOddsHandle, getUnderOverHandle, getBookmakerHandle, getFancyHandle }) => {
  const [matchOdds, setMatchOdds] = useState(null);
  const [underover, setUnderover] = useState([null, null, null]);
  const [bookmakerData, setBookmakerData] = useState([null, null, null,null,null]);
  const [fancy, setFancy] = useState(null);
  let subscribeChannels = useRef([]);
  const wsRef = useRef(null);
  let heartbeatInterval;

  const updateUnderOver = (index, value) => {
    setUnderover((prev) => {
      const updated = [...prev]; // Create a shallow copy of the current array
      updated[index] = value;   // Update the specific position
      return updated;           // Return the new array
    });
  };

  const updateBookMaker = (index, value) => {
    setBookmakerData((prev) => {
      const updated = [...prev]; // Create a shallow copy of the current array
      updated[index] = value;   // Update the specific position
      return updated;           // Return the new array
    });
  };

  useEffect(() => {
    getMatchOddsHandle(matchOdds);
  }, [matchOdds, getMatchOddsHandle]);

  useEffect(() => {
    getUnderOverHandle(underover);
  }, [underover,getUnderOverHandle]);

  useEffect(() => {
    getBookmakerHandle(bookmakerData);
  }, [bookmakerData,getBookmakerHandle]);

  useEffect(() => {
    getFancyHandle(fancy);
  }, [fancy,getFancyHandle]);

  const startWebSocket = () => {
    const ws = new WebSocket('wss://ag-api.supermaster.live/');
    wsRef.current = ws;

    ws.onopen = () => {
      console.log('11111--Connected to WebSocket server');

          let mo = (eventAccess?.matchData?.markets?.matchOdds?.find((item) => item.marketType === "MATCH_ODDS" || item.marketType === "TOURNAMENT_WINNER" || item.marketType === "WIN"));
      
          let underover05 = (eventAccess?.matchData?.markets?.matchOdds?.find((item) => item.marketType === "OVER_UNDER_05"));
          let underover25 = (eventAccess?.matchData?.markets?.matchOdds?.find((item) => item.marketType === "OVER_UNDER_25"));
          let underover15 = (eventAccess?.matchData?.markets?.matchOdds?.find((item) => item.marketType === "OVER_UNDER_15"));

          let bom1 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.marketName === "Bookmaker"));
          let bom2 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.marketName === "Bookmaker 0 Commission"))
          let bom4 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.marketName === "To Win The Toss"))
          let bom5 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.marketName === "Special Maker"))
          let bom3 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.bettingType === "BOOKMAKER" && !["Bookmaker", "Bookmaker 0 Commission", "To Win The Toss", "Special Maker"].includes(item.marketName))) 
      
      // Subscribe to topics
        if (!subscribeChannels.current.includes(`${config.Topic_Bookmaker}/${bom1?.marketId}`)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Bookmaker}/${bom1?.marketId}` }));
          subscribeChannels.current.push(`${config.Topic_Bookmaker}/${bom1?.marketId}`);
        }else{
          console.log(subscribeChannels.current,"BM1 already subscribe");
        }

        if (!subscribeChannels.current.includes(`${config.Topic_Bookmaker}/${bom2?.marketId}`)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Bookmaker}/${bom2?.marketId}` }));
          subscribeChannels.current.push(`${config.Topic_Bookmaker}/${bom2?.marketId}`);
        }else{
          console.log(subscribeChannels.current,"BM2 already subscribe");
        }

        if (!subscribeChannels.current.includes(`${config.Topic_Bookmaker}/${bom3?.marketId}`)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Bookmaker}/${bom3?.marketId}` }));
          subscribeChannels.current.push(`${config.Topic_Bookmaker}/${bom3?.marketId}`);
        }else{
          console.log(subscribeChannels.current,"BM3 already subscribe");
        }
        if (!subscribeChannels.current.includes(`${config.Topic_Bookmaker}/${bom4?.marketId}`)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Bookmaker}/${bom4?.marketId}` }));
          subscribeChannels.current.push(`${config.Topic_Bookmaker}/${bom4?.marketId}`);
        }else{
          console.log(subscribeChannels.current,"BM4 already subscribe");
        }
        if (!subscribeChannels.current.includes(`${config.Topic_Bookmaker}/${bom5?.marketId}`)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Bookmaker}/${bom5?.marketId}` }));
          subscribeChannels.current.push(`${config.Topic_Bookmaker}/${bom5?.marketId}`);
        }else{
          console.log(subscribeChannels.current,"BM5 already subscribe");
        }
             
          if (!subscribeChannels.current.includes(`${config.Topic_Match_Odds}${mo?.marketId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Match_Odds}${mo?.marketId}` }));            
            subscribeChannels.current.push(`${config.Topic_Match_Odds}${mo?.marketId}`);
          }else{
            console.log(subscribeChannels.current,"Match Odds already subscribe");
          }
          
          if (!subscribeChannels.current.includes(`${config.Topic_Match_Odds}${underover05?.marketId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Match_Odds}${underover05?.marketId}` }));
            subscribeChannels.current.push(`${config.Topic_Match_Odds}${underover05?.marketId}`);
          }else{
            console.log(subscribeChannels.current,"underOver05 already subscribe");
          }

          if (!subscribeChannels.current.includes(`${config.Topic_Match_Odds}${underover15?.marketId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Match_Odds}${underover15?.marketId}` }));
            subscribeChannels.current.push(`${config.Topic_Match_Odds}${underover15?.marketId}`);
          }else{
            console.log(subscribeChannels.current,"Unnderover15 already subscribe");
          }

          if (!subscribeChannels.current.includes(`${config.Topic_Match_Odds}${underover25?.marketId}`)) {
            ws.send(JSON.stringify({ action: 'subscribe', topic: `${config.Topic_Match_Odds}${underover25?.marketId}` }));
            subscribeChannels.current.push(`${config.Topic_Match_Odds}${underover25?.marketId}`);
          }else{
            console.log(subscribeChannels.current,"UnderOver25 already subscribe");
          }

  

        const topic = `${config.Topic_Fancy}${eventId}`;
        
        if (!subscribeChannels.current.includes(topic)) {
          ws.send(JSON.stringify({ action: 'subscribe', topic }));
          subscribeChannels.current.push(topic);
        }else{
          console.log(subscribeChannels.current,"Fancy already subscribe");
        }

      heartbeatInterval = setInterval(() => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify({ action: 'ping' }));
          console.log("Pingggggggggg");
        } else if (ws.readyState === WebSocket.CLOSED) {
          clearInterval(heartbeatInterval);
          reconnectWebSocket(); // Function to reconnect
          console.log("reeeeeeeeeeeeeeeeeeeeeeTry");          
        }
      }, 10000); // 10 seconds
    };
    let toSetData = {}
    ws.onmessage = (event) => {
      console.log('parsedData0');
      const { topic, marketData } = JSON.parse(event.data);
      if (marketData) {

        let mo = (eventAccess?.matchData?.markets?.matchOdds?.find((item) => item.marketType === "MATCH_ODDS" || item.marketType === "TOURNAMENT_WINNER" || item.marketType === "WIN"));
      

        let underover05 = (eventAccess?.matchData?.markets?.matchOdds?.find((item) => item.marketType === "OVER_UNDER_05"));
        let underover25 = (eventAccess?.matchData?.markets?.matchOdds?.find((item) => item.marketType === "OVER_UNDER_25"));
        let underover15 = (eventAccess?.matchData?.markets?.matchOdds?.find((item) => item.marketType === "OVER_UNDER_15"));

        let bom1 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.marketName === "Bookmaker"));
        let bom2 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.marketName === "Bookmaker 0 Commission"))
        let bom4 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.marketName === "To Win The Toss"))
        let bom5 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.marketName === "Special Maker"))
        let bom3 = (eventAccess?.matchData?.markets?.bookmakers?.find((item) => item.bettingType === "BOOKMAKER" && !["Bookmaker", "Bookmaker 0 Commission", "To Win The Toss", "Special Maker"].includes(item.marketName))) 

        const parsedData = JSON.parse(marketData);
        if (topic.startsWith(config.Topic_Bookmaker)) {            
          if(bom1?.marketId.includes(topic.split('/').pop())){
            // console.log('parsedData01');
            updateBookMaker(0, parsedData);
          }
          if(bom2?.marketId.includes(topic.split('/').pop())){
            // console.log('parsedData02');
            updateBookMaker(1, parsedData);
          }
          if(bom4?.marketId.includes(topic.split('/').pop())){
            // console.log('parsedData03');
            updateBookMaker(3, parsedData);
          }
          if(bom5?.marketId.includes(topic.split('/').pop())){
            // console.log('parsedData04');
            updateBookMaker(4, parsedData);
          }
          if(bom3?.marketId.includes(topic.split('/').pop())){
            // console.log('parsedData05');
            updateBookMaker(2, parsedData);
          }
        }
        if (topic.startsWith(config.Topic_Match_Odds)) {          
          if (mo?.marketId.includes(topic.split('/').pop())) {
            // console.log('parsedData000');
            if(topic.split('/').pop() === "1.239849379"){
              // parsedData?.runners?[0].backPrices[0].price
              console.log(parsedData?.runners[0]?.backPrices[2]?.price,parsedData?.runners[0]?.backPrices[1]?.price,parsedData?.runners[0]?.backPrices[0]?.price);
              
            }
            setMatchOdds(parsedData);
          } 
            if(underover05?.marketId.includes(topic.split('/').pop())){
              // console.log('parsedData012');
              updateUnderOver(0, parsedData);
            }

            if(underover15?.marketId.includes(topic.split('/').pop())){
              // console.log('parsedData012');
              updateUnderOver(1, parsedData);
            }

            if(underover25?.marketId.includes(topic.split('/').pop())){
              // console.log('parsedData0124');
              updateUnderOver(2, parsedData);
            }
          
        }
        if (topic.startsWith(config.Topic_Fancy)) {
          // console.log('parsedData');
          let otherData = parsedData.filter((item) => item.status.trim() != 'CLOSED');
          setFancy(otherData);
          
          // parsedData
        }
      }
    };

    ws.onclose = () => {
      console.log('11111--WebSocket disconnected');
      clearInterval(heartbeatInterval);
      reconnectWebSocket();
    };

    ws.onerror = (error) => {
      console.log('11111--WebSocket error:', error);
      clearInterval(heartbeatInterval);
      reconnectWebSocket();
    };
  };

  const reconnectWebSocket = () => {
    console.log('11111--Reconnecting WebSocket...');
    setTimeout(() => {
      
      startWebSocket();
    }, 3000); // Reconnect after 3 seconds
  };

  const unsubscribeAll = () => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      subscribeChannels.current.forEach((topic) => {
        wsRef.current.send(JSON.stringify({ action: 'unsubscribe', topic }));
      });
      subscribeChannels.current = [];
    }
  };

  const unsubscribeTopic = (topicToRemove) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      const index = subscribeChannels.current.indexOf(topicToRemove);
      
      if (index !== -1) {
        wsRef.current.send(JSON.stringify({ action: 'unsubscribe', topic: topicToRemove }));
        subscribeChannels.current.splice(index, 1); // Remove only the specific topic
      }
    }
  };

  useEffect(()=>{
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
          // if (wsRef.current && wsRef.current.readyState !== WebSocket.OPEN) {
            console.log("Tab is active again, reconnecting WebSocket...");
            startWebSocket();
          // }
      }
  });
  },[])

  useEffect(() => {
      if (eventAccess) {
        startWebSocket();
      }

    return () => {
      if (wsRef.current && eventAccess) {
        wsRef.current.close();
        // unsubscribeAll();
        clearInterval(heartbeatInterval);
        console.log("diiiiiiiiiiiiiiiiiisconnect");
        
      }
    };
  }, [eventAccess,eventId]);

  return null;
};

export default NewOddsFeedComponenttt;
