import React, { useEffect } from 'react';

const ClarityScript = ({ user }) => {
  useEffect(() => {
    console.log(user,
        "11111111111111111"
    );
    
    const currentUserId = user && user.userName ? user.userName : ''; // Safely get the userName

    // Check if Clarity script is already added to avoid duplication
    const scriptId = 'clarity-script';
    if (document.getElementById(scriptId)) {
      window.clarity('set', 'userId', currentUserId);
      return;
    }

    // Create the Clarity script tag
    const script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://www.clarity.ms/tag/oq5jucfblb';

    // Set userId once the script is loaded
    script.onload = () => {
      if (currentUserId) {
        window.clarity('set', 'userId', currentUserId);
      } else {
        window.clarity('set', 'userId', ''); // Set an empty string if no userId is available
      }
    };

    // Error handling for loading the script
    script.onerror = () => {
      console.error('Failed to load Clarity script');
    };

    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup the script when the component is unmounted
    return () => {
      document.head.removeChild(script);
    };
  }, [user]);  // Re-run effect only when `user` changes

  return null;
};

export default ClarityScript;
