import { Routes, Route, Navigate, useLocation, Link } from "react-router-dom";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Header from "./components/common/header/Header";
import Home from "./pages/Home/Home";
import Footer from "./components/common/footer/Footer";
import Exchange from "./pages/exchange/Exchange";
import SideSportMenu from "./components/sidesportmenu/SideSportMenu";
import NotFound from "./components/common/NotFound";
import Inplay from "./pages/inplay/Inplay";
import ExchangeInner from "./pages/exchangeinner/ExchangeInner";
import Cricket from "./pages/cricket/Cricket";
import Football from "./pages/football/Football";
import Tennis from "./pages/tennis/Tennis";
import GreyHound from "./pages/greyhound/GreyHound";
import HorseRacing from "./pages/HorseRacing/Horseracing";
import LiveCasino from "./pages/live_casino/LiveCasino";
import Live_casinoInPlay from "./pages/Live_casinoInPlay/live_casinoInPlay";
import Royal_casino from "./pages/royal_casino/Royal_casino";
import Virtuals from "./pages/virtuals/Virtuals";
import Slot from "./pages/slots/Slot";
import Premium_sports from "./pages/premium_sports/Premium_sports";
import Profile from "./pages/profile/Profile";
import MyAccountStatment from "./pages/myAccountStatment/MyAccountStatment";
import MyPlStatement from "./pages/myPlStatement/MyPlStatement";
import MyPlStatementEvent from "./pages/myPlStatement/MyPlStatementEvent";
import MyPlStatementMatch from "./pages/myPlStatement/MyPlStatementMatch";
import Mybets from "./pages/mybets/Mybets";
import MyGameReport from "./pages/myGameReport/MyGameReport";
import MyGameReportEvent from "./pages/myGameReport/MyGameReportEvent";
import MyGameReportMatch from "./pages/myGameReport/MyGameReportMatch";
import MobileBottomMenu from "./components/common/mobilebottommenu/MobileBottomMenu";
import MyProfile from "./pages/myprofile/MyProfile";
import { useExchangeWebSocket } from "./components/allsportdata/useExchangeWebSocket";
import { useContext, useState } from "react";
import { PopupContext } from "./components/LoginPopup/LoginPopupContext";
import MultiMarket from "./pages/multiMarket/MultiMarket";
import Election from "./pages/election/Election";
import Kabaddi from "./pages/kabaddi/Kabaddi";
import MyCommission from "./pages/myCommission/MyCommission";
import MyCommissionSport from "./pages/myCommission/MyCommissionSport";
import MyCommissionSeries from "./pages/myCommission/MyCommissionSeries";
import ExchangeInnerHG from "./pages/exchangeinner/ExchangeInnerHG";
import MyCommissionEvent from "./pages/myCommission/MyCommissionEvent";
import Home2 from "./pages/Home/Home2";
import ExchangeInnerHGEvent from "./pages/exchangeinner/ExchangeInnerHGEvent";
import SidebarSportMenu from "./components/sidebarsportmenu/SidebarSportMenu";
import DepositRequest from "./pages/depositRequest/DepositRequest";
import WithdrawalRequests from "./pages/depositRequest/WithdrawalRequests";
import ManageAccounts from "./pages/manageAccount/ManageAccounts";
import BonusStatment from "./pages/myAccountStatment/BonusStatment";
import FlotIcon from "./pages/FlotIcon";
import Registration from "./pages/registration/Registration";
import { useEffect } from "react";
import ClarityScript from "./ClarityScript";
import ExchangeInner2 from "./pages/exchangeinner/ExchangeInner2";
import ExchangeInnerHG2 from "./pages/exchangeinner/ExchangeInnerHG2";
import ExchangeInnerHGEvent2 from "./pages/exchangeinner/ExchangeInnerHGEvent2";
import ExchangeInner3 from "./pages/exchangeinner/ExchangeInner3";
import ExchangeInnerHG3 from "./pages/exchangeinner/ExchangeInnerHG3";
import ExchangeInnerHGEvent3 from "./pages/exchangeinner/ExchangeInnerHGEvent3";

export default function ChildApp() {
  const { isPopupOpen, closePopup, isLoggedIn, sessionUpdatevalue } = useContext(PopupContext);
  const [isMaintanace, setisMaintanace] = useState(false);
  const [isCalled, setIsCalled] = useState(false);
  useExchangeWebSocket();
  const location = useLocation();
  useEffect(() => {
    // console.log(location.pathname);
  }, [location,isLoggedIn]);

  useEffect(() => {
    callGameReport();
      const intervalId = setInterval(callGameReport, 5000);
      return () => {clearInterval(intervalId);}},[]);

      const callGameReport = () => {
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", `Bearer ${sessionStorage.getItem("token")}`);
    
          const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };
          fetch(`${process.env.REACT_APP_BASE_URL}/maintanace?whiteLabel=${window.location.hostname}`, requestOptions)
            .then(async (response) => {
              let paresData = await response.json();
              setisMaintanace(paresData.maintananceStatus);
              if (paresData.maintananceStatus) {
                if (!isCalled) {
                  Navigate("/");
                }
                setIsCalled(true);
              }
            })
            .catch((error) => {
              // console.log("logInCheck - ",error);
            });
      };
    

    return (
      <>
        {/* {isLoggedIn || sessionStorage.getItem('userinfo') ? <ClarityScript user={sessionStorage.getItem('userinfo')} />:''} */}
        {isMaintanace ? <> 
          <div className="match-not-found">
                      <h2><span></span>Our server is currently undergoing maintenance. Services may be temporarily unavailable. We apologize for the inconvenience and appreciate your patience.</h2>
                      <p>Opps! Please try again later</p>
                      <div className="error-404-btn">
                              <button className="btn" onClick={()=>window.location.reload()}>home</button>
                      </div>
                  </div>
        </> : <>
          {location.pathname.startsWith("/registration/") ? '' :<Header/>}
        {location.pathname.startsWith("/registration/") ? '' :<FlotIcon />}
        <main className="main-content">
          <SideSportMenu />
          <SidebarSportMenu />
          <Routes>
            <Route path="/*" element={<Navigate to="/" />} />
            <Route path="/" element={<Home />} />
            <Route path="/NewHome" element={<Home2 />} />
            <Route path="/exchange" element={<Exchange />} />
            <Route path="/exchange/inPlay" element={<Inplay />} />
            <Route path="/exchange/cricket" element={<Cricket />} />
            <Route path="/exchange/football" element={<Football />} />
            <Route path="/exchange/tennis" element={<Tennis />} />
            <Route path="/exchange/election" element={<Election />} />
            <Route path="/exchange/kabaddi" element={<Kabaddi />} />
            <Route path="/exchange/horseracing" element={<HorseRacing />} />
            <Route path="/exchange/greyhound" element={<GreyHound />} />
            <Route path="/exchange/multimarkets" element={<MultiMarket />} />
            <Route path="/exchange_inPlay/:eventId" element={<ExchangeInner />} />
            {process.env.REACT_APP_ENV === 'development' ? <Route path="/exchange_inPlay2/:eventId" element={<ExchangeInner2 />} />:'' }
            {process.env.REACT_APP_ENV === 'development' ? <Route path="/exchange_inPlay2/:eventId/:mId" element={<ExchangeInnerHG2 />} />:'' }
            {process.env.REACT_APP_ENV === 'development' ? <Route path="/exchange_inPlayGH2/:eventId" element={<ExchangeInnerHGEvent2 />} />:'' }
            <Route path="/exchange_inPlayGH/:eventId" element={<ExchangeInnerHGEvent />} />
            <Route path="/exchange_inPlay/:eventId/:marketId" element={<ExchangeInnerHG />} />
            <Route path="/live_casino" element={<LiveCasino />} />
            <Route path="/Royal_casino" element={<Royal_casino />} />
            <Route path="/virtuals" element={<Virtuals />} />
            <Route path="/slots" element={<Slot />} />
            <Route path="/premium_sports" element={<Premium_sports />} />
            <Route path="/live_casinoInPlay/:gameId" element={<Live_casinoInPlay />} />

            <Route path="/profile" element={<Profile />} />
            {JSON.parse(localStorage.getItem("isB2c") || "false") ? <Route path="/registration/:refCode" element={<Registration/>} /> :''}
            <Route path="/myAccountStatment" element={<MyAccountStatment />} />
            <Route path="/MyPlStatement" element={<MyPlStatement />} />
            <Route path="/MyPlStatement/:eventname" element={<MyPlStatementEvent />} />
            <Route path="/MyPlStatement/:eventname/:matchname" element={<MyPlStatementMatch />} />
            <Route path="/mybets" element={<Mybets />} />
            {JSON.parse(localStorage.getItem("isB2c") || "false") ? <Route path="/depositRequest" element={<DepositRequest />} />:""}
            {JSON.parse(localStorage.getItem("isB2c") || "false") ?<Route path="/withdrawalRequest" element={<WithdrawalRequests />} />:""}
            {JSON.parse(localStorage.getItem("isB2c") || "false") ?<Route path="/manageAccounts" element={<ManageAccounts />} />:""}
            {JSON.parse(localStorage.getItem("isB2c") || "false") ?<Route path="/bonustatement" element={<BonusStatment />} />:""}
            <Route path="/myGameReport" element={<MyGameReport />} />
            <Route path="/myGameReport/:eventname" element={<MyGameReportEvent />} />
            <Route path="/myGameReport/:eventname/:matchname" element={<MyGameReportMatch />} />
            <Route path="/myCommissionReports" element={<MyCommission />} />
            <Route path="/myCommissionReports/:sportId" element={<MyCommissionSport />} />
            <Route path="/myCommissionReports/series/:seriesId" element={<MyCommissionSeries />} />
            <Route path="/myCommissionReports/event/:eventId" element={<MyCommissionEvent />} />
            <Route path="/myprofile" element={<MyProfile />} />
          </Routes>
        </main>
        {location.pathname.startsWith("/registration/") ? '' :<Footer />}
        {location.pathname.startsWith("/registration/") ? '' :<MobileBottomMenu />}
        </>}
        
      </>
    );
  }
